<template>
  <div>
    <kandidat-menu
      :kandidat="kandidatData"
      :active-item="activeItem"
    />
    <b-card
      no-body
    >
      <b-card-body
        :title="$t('TeorijskaObuka')"
      >
        <b-row>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <!-- basic -->
            <b-form-group
              :label="$t('DatumPocetkaTeorijskeObuke')"
              label-for="datum_pocetka_teorijske_obuke"
            >
              <b-form-datepicker
                id="datum_pocetka_teorijske_obuke"
                v-model="data.datum_pocetka_teorijske_obuke"
                :placeholder="$t('DatumPocetkaTeorijskeObuke')"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                :locale="this.$i18n.locale"
                :hide-header="hideHeader"
                today-button
                reset-button
                close-button
                show-decade-nav
                :label-today-button="$t('Danas')"
                :label-reset-button="$t('Ponisti')"
                :label-close-button="$t('Zatvori')"
                :label-prev-year="$t('PrethodnaGodina')"
                :label-prev-month="$t('PrethodniMesec')"
                :label-next-year="$t('SledecaGodina')"
                :label-next-month="$t('SledeciMesec')"
                :label-current-month="$t('TekuciMesec')"
                :label-prev-decade="$t('MinusDesetGodina')"
                :label-next-decade="$t('PlusDesetGodina')"
                label-help=""
                no-flip
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <!-- basic -->
            <b-form-group
              :label="$t('DatumZavrsetkaTeorijskeObuke')"
              label-for="datum_zavrsetka_teorijske_obuke"
            >
              <b-form-datepicker
                id="datum_zavrsetka_teorijske_obuke"
                v-model="data.datum_zavrsetka_teorijske_obuke"
                :placeholder="$t('DatumZavrsetkaTeorijskeObuke')"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                :locale="this.$i18n.locale"
                :hide-header="hideHeader"
                today-button
                reset-button
                close-button
                show-decade-nav
                :label-today-button="$t('Danas')"
                :label-reset-button="$t('Ponisti')"
                :label-close-button="$t('Zatvori')"
                :label-prev-year="$t('PrethodnaGodina')"
                :label-prev-month="$t('PrethodniMesec')"
                :label-next-year="$t('SledecaGodina')"
                :label-next-month="$t('SledeciMesec')"
                :label-current-month="$t('TekuciMesec')"
                :label-prev-decade="$t('MinusDesetGodina')"
                :label-next-decade="$t('PlusDesetGodina')"
                label-help=""
                no-flip
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <!-- basic -->
            <b-form-group
              :label="$t('DatumZavrsetkaDodatneTo')"
              label-for="datum_zavrsetka_dodatne_to"
            >
              <b-form-datepicker
                id="datum_zavrsetka_dodatne_to"
                v-model="data.datum_zavrsetka_dodatne_to"
                :placeholder="$t('DatumZavrsetkaDodatneTo')"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                :locale="this.$i18n.locale"
                :hide-header="hideHeader"
                today-button
                reset-button
                close-button
                show-decade-nav
                :label-today-button="$t('Danas')"
                :label-reset-button="$t('Ponisti')"
                :label-close-button="$t('Zatvori')"
                :label-prev-year="$t('PrethodnaGodina')"
                :label-prev-month="$t('PrethodniMesec')"
                :label-next-year="$t('SledecaGodina')"
                :label-next-month="$t('SledeciMesec')"
                :label-current-month="$t('TekuciMesec')"
                :label-prev-decade="$t('MinusDesetGodina')"
                :label-next-decade="$t('PlusDesetGodina')"
                label-help=""
                no-flip
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <!-- basic -->
            <b-form-group
              :label="$t('DatumPolaganjaTeorijskogIspita')"
              label-for="datum_polaganja_teorijskog_ispita"
            >
              <b-form-datepicker
                id="datum_polaganja_teorijskog_ispita"
                v-model="data.datum_polaganja_teorijskog_ispita"
                :placeholder="$t('DatumPolaganjaTeorijskogIspita')"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                :locale="this.$i18n.locale"
                :hide-header="hideHeader"
                today-button
                reset-button
                close-button
                show-decade-nav
                :label-today-button="$t('Danas')"
                :label-reset-button="$t('Ponisti')"
                :label-close-button="$t('Zatvori')"
                :label-prev-year="$t('PrethodnaGodina')"
                :label-prev-month="$t('PrethodniMesec')"
                :label-next-year="$t('SledecaGodina')"
                :label-next-month="$t('SledeciMesec')"
                :label-current-month="$t('TekuciMesec')"
                :label-prev-decade="$t('MinusDesetGodina')"
                :label-next-decade="$t('PlusDesetGodina')"
                label-help=""
                no-flip
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-body
        :title="$t('PrakticnaObuka')"
      >
        <b-row>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <!-- basic -->
            <b-form-group
              :label="$t('DatumZavrsetkaPrakticneObuke')"
              label-for="datum_zavrsetka_prakticne_obuke"
            >
              <b-form-datepicker
                id="datum_zavrsetka_prakticne_obuke"
                v-model="data.datum_zavrsetka_prakticne_obuke"
                :placeholder="$t('DatumZavrsetkaPrakticneObuke')"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                :locale="this.$i18n.locale"
                :hide-header="hideHeader"
                today-button
                reset-button
                close-button
                show-decade-nav
                :label-today-button="$t('Danas')"
                :label-reset-button="$t('Ponisti')"
                :label-close-button="$t('Zatvori')"
                :label-prev-year="$t('PrethodnaGodina')"
                :label-prev-month="$t('PrethodniMesec')"
                :label-next-year="$t('SledecaGodina')"
                :label-next-month="$t('SledeciMesec')"
                :label-current-month="$t('TekuciMesec')"
                :label-prev-decade="$t('MinusDesetGodina')"
                :label-next-decade="$t('PlusDesetGodina')"
                label-help=""
                no-flip
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <!-- basic -->
            <b-form-group
              :label="$t('DatumZavrsetkaDodatnePo')"
              label-for="datum_zavrsetka_dodatne_po"
            >
              <b-form-datepicker
                id="datum_zavrsetka_dodatne_po"
                v-model="data.datum_zavrsetka_dodatne_po"
                :placeholder="$t('DatumZavrsetkaDodatnePo')"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                :locale="this.$i18n.locale"
                :hide-header="hideHeader"
                today-button
                reset-button
                close-button
                show-decade-nav
                :label-today-button="$t('Danas')"
                :label-reset-button="$t('Ponisti')"
                :label-close-button="$t('Zatvori')"
                :label-prev-year="$t('PrethodnaGodina')"
                :label-prev-month="$t('PrethodniMesec')"
                :label-next-year="$t('SledecaGodina')"
                :label-next-month="$t('SledeciMesec')"
                :label-current-month="$t('TekuciMesec')"
                :label-prev-decade="$t('MinusDesetGodina')"
                :label-next-decade="$t('PlusDesetGodina')"
                label-help=""
                no-flip
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <!-- basic -->
            <b-form-group
              :label="$t('DatumPolaganjaPrakticnogIspita')"
              label-for="datum_polaganja_prakticnog_ispita"
            >
              <b-form-datepicker
                id="datum_polaganja_prakticnog_ispita"
                v-model="data.datum_polaganja_prakticnog_ispita"
                :placeholder="$t('DatumPolaganjaPrakticnogIspita')"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                :locale="this.$i18n.locale"
                :hide-header="hideHeader"
                today-button
                reset-button
                close-button
                show-decade-nav
                :label-today-button="$t('Danas')"
                :label-reset-button="$t('Ponisti')"
                :label-close-button="$t('Zatvori')"
                :label-prev-year="$t('PrethodnaGodina')"
                :label-prev-month="$t('PrethodniMesec')"
                :label-next-year="$t('SledecaGodina')"
                :label-next-month="$t('SledeciMesec')"
                :label-current-month="$t('TekuciMesec')"
                :label-prev-decade="$t('MinusDesetGodina')"
                :label-next-decade="$t('PlusDesetGodina')"
                label-help=""
                no-flip
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-body>
        <b-row>
          <b-col
            md="12"
            xl="12"
          >
            <b-form-group
              :label="$t('Napomena')"
              label-for="napomena"
            >
              <b-form-textarea
                id="napomena"
                v-model="data.napomena"
                :placeholder="$t('Napomena')"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-body>
        <b-row>
          <b-col
            md="4"
            xl="4"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="IzmeniPodatke"
            >
              {{ $t("Snimi") }}
            </b-button>
            <router-link :to="{ name: 'autoskola-kandidati' }">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
              >
                {{ $t("Odustani") }}
              </b-button>
            </router-link>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BCard, BCardBody, BFormDatepicker, BButton, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import KandidatMenu from '../kandidati/KandidatMenu.vue'

export default {
  components: {
    'kandidat-menu': KandidatMenu,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormDatepicker,
    BButton,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      podaci: [],
      data: {},
      kandidatData: {},
      hideHeader: true,
      formErrors: [],
      activeItem: 'podaci_o_obuci',
    }
  },
  mounted() {
    this.$http.get(`/autoskole-administratori/kandidati/podaci-o-obuci/${this.$route.params.id}`).then(response => {
      this.data = response.data.podaci_o_obuci
      this.kandidatData = response.data.kandidat
    })
  },
  methods: {
    IzmeniPodatke() {
      const podaci = { ...this.data }

      this.$http.put(`/autoskole-administratori/kandidati/podaci-o-obuci/${this.kandidatData.id}/update`, podaci)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('PodaciSuSnimljeni'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.formErrors = error.response.data.errors
          }
        })
    },
  },
}
</script>
